import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  Item,
  FancyHeader2,
  DetailTemplate
} from '../components/common'
import {
  ItemCarousel
} from '../components/carousel'

class ProductTemplate extends React.Component {
  renderCollections (product) {
    if (!product.collection || product.showCollections === false) {
      return
    }
    return (
      <div>
        <FancyHeader2>See this in other outfits</FancyHeader2>
        <ItemCarousel gallery={product.collection.map(c => {
          const url = `/collection/${ c.slug }/`
          return (
            <Item
              key={url}
              url={url}
              mobileDivisor={2}
              image={get(c, 'images[0].fixed')}
              imageTitle={get(c, 'images[0].title')} />
          )
        })} />
      </div>
    )
  }

  renderPersonalized (product) {
    if (!product.personalizedItems || product.showPersonalized === false) {
      return
    }
    return (
      <div>
        <FancyHeader2>You May Also Like</FancyHeader2>
        <ItemCarousel gallery={ product.personalizedItems.map(p => {
          const url = p.__typename === 'ContentfulProduct'
            ? `/product/${ p.slug }/`
            : `/collection/${ p.slug }/`
          return (
            <Item
              key={url}
              url={url}
              mobileDivisor={2}
              image={get(p, 'images[0].fixed')}
              imageTitle={get(p, 'images[0].title')}
              hoverTitle={true}
              /* title={p.title} */
            />)
        })} />
      </div>
    )
  }

  renderRelated (product) {
    if (!product.relatedProducts || product.showRelatedProducts === false) {
      return
    }
    return (
      <div>
        <FancyHeader2>Get the Look</FancyHeader2>
        <ItemCarousel gallery={ product.relatedProducts.map(p => {
          const url = `/product/${ p.slug }/`
          return (
            <Item
              key={url}
              url={url}
              mobileDivisor={2}
              comingSoon={p.comingSoon}
              image={get(p, 'images[0].fixed')}
              imageTitle={get(p, 'images[0].title')}
              hoverTitle={true}
              /* title={p.title} */
            />)
        })} />
      </div>
    )
  }

  render () {
    const product = get(this.props, 'data.contentfulProduct')
    return (
      <DetailTemplate
        title={product.title}
        location={this.props.location}
        comingSoon={product.comingSoon}
        gallery={product.images}
        review={product.review}
        productLink={product.productLink}>
        { this.renderRelated(product) }
        { this.renderCollections(product) }
        { this.renderPersonalized(product) }
      </DetailTemplate>
    )
  }
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      title
      productLink
      comingSoon
      showRelatedProducts
      showCollections
      showPersonalized
      review {
        review
        childMarkdownRemark {
          html
        }
      }
      images {
        title
        image: fixed(width:1200) {
          width
          height
          src
        }
        thumb: fixed(width:800) {
          width
          height
          src
        }
      }
      relatedProducts {
        title
        slug

        images {
          title
          fixed(width:400) {
            width
            height
            src
          }
        }
      }
      personalizedItems {
        __typename
          ... on ContentfulProduct {
            title
            slug

            images {
              title
              fixed(width:400) {
                width
                height
                src
              }
            }
          }
      }
      collection {
        title
        slug

        images {
          title
          fixed(width:400) {
            width
            height
            src
          }
        }
      }
    }
  }
`
